import PlacesAutocomplete from "stimulus-places-autocomplete"

export default class extends PlacesAutocomplete {
  static values = { country: String }

  connect() {
    super.connect()
  }

  initAutocomplete () {
    super.initAutocomplete()
  }

  placeChanged () {
    super.placeChanged()

    const event = new Event("place-changed", { bubbles: true, cancelable: true });
    window.dispatchEvent(event);
  }

  get autocompleteOptions () {
    const country = this.countryValue == "US" ? ["us","vi"] : this.countryValue;
    console.log(country)

    return {
      fields: ["address_components", "geometry"],
      types: ["geocode"],
      componentRestrictions: {
        country: country
      }
    }
  }
}
